import React from 'react';
import tradeTokensImage from 'images/trade_tokens_image.png';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';

const TradeTokens = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.InfoBox>
          <Styles.Title component="h1" variant="h4">
            Choose your exit strategy yourself
          </Styles.Title>
          <Styles.InfoText component="p" fontWeight="700">
            Once the renovation has begun you can sell and trade your digital
            shares:
          </Styles.InfoText>

          <Styles.BenefitsBox>
            <Styles.BenefitsText component="p">
              <Styles.CheckIcon />
              On the user-friendly platform, you have the option to either sell
              or purchase digital shares.
            </Styles.BenefitsText>
            <Styles.BenefitsText component="p">
              <Styles.CheckIcon />
              In the near future, we will partner with external exchange
              platforms, enabling you to trade digital shares externally.
            </Styles.BenefitsText>
          </Styles.BenefitsBox>
        </Styles.InfoBox>

        <Styles.PictureBox>
          <Styles.Picture
            src={tradeTokensImage}
            component={'img'}
            alt="Illustration of people trading real estate asset backed tokens for euro or bitcoin."
            height={1}
            width={1}
          />
        </Styles.PictureBox>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default TradeTokens;
