import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { StyledPropsFix } from 'types';

export const OverlayStyles = styled(Box)<{ hidden: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2300;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  .dislaimer-paragraph {
    font-family: Roboto Regular;
    padding-bottom: 1.8em;
    font-size: 0.9em;
    /* color: #8c8c8c; */
    color: #000;
  }
  .dislaimer-title {
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 400;
    font-size: 32px;
    font-style: normal;
    padding-bottom: 0.6em;
    align-self: flex-start;
  }

  .disclaimer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    height: auto;
    max-width: 1080px;
    max-height: 70%;

    width: 70%;
    min-width: 0%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    padding: 38px;
    box-shadow: 1px 1px 3px 0px rgba(2 2 2 / 23%);

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 80%;
    }
  }

  .disclaimer-button {
    text-transform: none;
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.secondary};
    background-color: rgb(64 130 109 / 78%);
    white-space: nowrap;
    &:hover {
      background-color: #58a88f;
    }
    font-size: 1.125em;
    margin-left: -2.6em;
    margin-right: 0;

    ${({ theme }) => theme.breakpoints.only('xs')} {
      margin-left: 0;
      padding: 1em 2em;
      min-height: 3.5em;
    }
  }
`;
export const Content = styled(Box)``;
export const Title = styled(Typography)`` as StyledPropsFix;
export const Text = styled(Typography)`` as StyledPropsFix;
export const ConfirmButton = styled(Button)`` as StyledPropsFix;
