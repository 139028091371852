import React from 'react';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';

const VideoSection = ({
  title,
  video,
}: {
  title?: string;
  video: string;
}): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.Title component="h1" variant="h4" color="text.primary">
          {title || ''}
        </Styles.Title>
        <iframe
          width="100%"
          src={video}
          style={{ border: 'none', aspectRatio: '16/9' }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default VideoSection;
