import React from 'react';
import { RightArrow } from 'views/Company/components/CareersSection/CareersSection';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import introductionImage from 'images/introduction_image.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as Styles from './styles';
import { GREV55_SITE_LINK } from 'links';

const Introducing = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.Title component="h1" variant="h4" color="text.primary">
          Introducing Coreestate Digitized Physical Real Estate and Security
          tokens
        </Styles.Title>
        <Styles.SubTitle component="h2" color="text.primary" variant="h7">
          Buy fractionalized rental properties for only €100.
          <Styles.ProjectLink href={GREV55_SITE_LINK}>
            See available properties{' '}
            <ArrowForwardIcon
              style={{ fontSize: '1rem', transform: 'translateY(25%)' }}
            />
          </Styles.ProjectLink>
        </Styles.SubTitle>

        <Styles.InfoBox>
          <Styles.InfoTitle component="h1" variant="h6">
            What is Coreestate issued Digital Share, and do I own the property?
          </Styles.InfoTitle>
          <Styles.InfoText component="p">
            Coreestate digital shares are issued on the Ethereum blockchain and
            represent investors&apos; ownership of the specific property. By
            purchasing the Coreestate issued digital share, the investor becomes
            a co-owner of the land, buildings, and profits.
          </Styles.InfoText>
          <Styles.TokenLink href="/learn">
            More about Digital Share <RightArrow />
          </Styles.TokenLink>
        </Styles.InfoBox>
        <Styles.PictureBox>
          <Styles.SectionPicture
            src={introductionImage}
            alt="Illustration of human hands who are investing in Coreestate tokenized real estate tokens."
            component={'img'}
            height={1}
            width={1}
            maxWidth={544}
            marginLeft={'1rem'}
            sx={{ objectFit: 'contain' }}
          />
        </Styles.PictureBox>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default Introducing;
