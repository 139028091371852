import React from 'react';
import earnIncomeImage from 'images/earn_income_image.png';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';

const Benefits = () => (
  <Styles.BenefitsBox>
    {['Full transparency', 'Asset-backed security', 'No hidden fees'].map(
      (text, index) => (
        <React.Fragment key={index}>
          <Styles.BenefitsText component="p">
            <Styles.CheckIcon />
            {text}
          </Styles.BenefitsText>
        </React.Fragment>
      ),
    )}
  </Styles.BenefitsBox>
);

const EarnIncome = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);
  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.InfoBox>
          <Styles.Title component="h1" variant="h4" color="text.primary">
            Earn income from an increase in the token price and monthly
            dividends paid by tenants
          </Styles.Title>
          <Styles.InfoText component="p">
            The value of digital share is correlated to the real estate price
            and aftermarket trading price.
          </Styles.InfoText>
          <Styles.InfoText component="p">
            Additionally, this investment offers quarterly dividends paid by
            tenants. Dividends accrue monthly and are paid quarterly to your
            Coreestate account.
          </Styles.InfoText>
          <Benefits />
        </Styles.InfoBox>

        <Styles.PictureBox>
          <Styles.Picture
            src={earnIncomeImage}
            component={'img'}
            alt="Illustration of phone that is displaying Coreestate logo and pie chart related to investment offering. "
            height={1}
            width={1}
          />
        </Styles.PictureBox>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default EarnIncome;
