import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import Link from 'layouts/Main/components/Footer/FooterLink';

export const Title = styled(Typography)`
  ${({ theme }) => `
  display: flex;
  font-family: Poppins SemiBold;
  text-align: center;

  ${theme.breakpoints.up('md')} {
   font-size: 2.1em;
   margin: 0 4.2em;
   line-height: 50px;
   order: initial;
  }
  ${theme.breakpoints.only('sm')} {
   font-size: 1.6em;
   margin: 0 1.0em;
   line-height: 40px;
   order: 2;
  }
  ${theme.breakpoints.only('xs')} {
   font-size: 1.2em;
   margin: 0 0;
   line-height: inherit;
   order: 2;
  }
  `}
` as StyledPropsFix;

export const SubTitle = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;

  ${theme.breakpoints.up('md')} {
   font-size: 1em;
   line-height: 45px;
   text-align: center;
   order: initial;
  }  
  ${theme.breakpoints.only('sm')} {
   font-size: 0.8em;
   line-height: 35px;
   text-align: right;
   padding-top: 16px;
   order: 3;
  }  
  ${theme.breakpoints.only('xs')} {
   font-size: 0.7em;
   line-height: inherit;
   text-align: center;
   padding-top: 16px;
   order: 3;
  }
`}
` as StyledPropsFix;

export const SectionPicture = styled(Box)`` as StyledPropsFix;

export const InfoBox = styled(Paper)`
  ${({ theme }) => `
  background: #1c5695;
  padding: 2.8em;
  align-self: start;
  width: 338px;
  margin-top: 4em;

  ${theme.breakpoints.up('md')} {
   flex-direction: initial;
   display: inline-block;
   align-self: auto;
   order: initial;
  }
  ${theme.breakpoints.down('md')} {
   flex-direction: column;
   display: flex;
   align-self: auto;
   order: 4;
  }  
`}
`;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  ${({ theme, sizeofgrid }) => `
  height: 100;
  position: relative;

  ${theme.breakpoints.up('md')} {
    display: initial;
    flex-direction: unset;
    align-items: initial;
    ${sizeofgrid && `max-width: ${sizeofgrid}px;`}
  }
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }  

`}
`;

export const ContentBox = styled(Box)`
  ${({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${theme.palette.background.paper}
  `}
`;

export const InfoTitle = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Bold;
  padding-bottom: 1em;

  ${theme.breakpoints.up('md')} {
    font-size: 1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.8em;
  }
`}
` as StyledPropsFix;

export const InfoText = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-bottom: 1em;

  ${theme.breakpoints.up('md')} {
    font-size: 1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.8em;
  }
`}
` as StyledPropsFix;

export const TokenLink = styled(Link)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  padding-top: 1em;

  ${theme.breakpoints.up('md')} {
    font-size: 1em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 0.8em;
  }
`}
` as StyledPropsFix;

export const ProjectLink = styled(Link)`
  ${({ theme }) => `
  font-family: Roboto Regular;
  white-space: nowrap;
  padding-left: 1em;

  ${theme.breakpoints.up('md')} {
   font-size: 1em;
   display: inline;
  }  
  ${theme.breakpoints.only('sm')} {
   font-size: 0.8em;
   display: block;
  }  
  ${theme.breakpoints.only('xs')} {
   font-size: 0.7em;
   display: initial;
  }
`}
`;

export const PictureBox = styled(Box)`
  ${({ theme }) => `
  align-self: initial;

  ${theme.breakpoints.up('md')} {
   display: inline-block;
   position: absolute;
   width: 34rem;
   right: 0;
   order: initial;
  }
  ${theme.breakpoints.only('sm')} {
   display: flex;
   position: relative;
   width: 28rem;
   order: 3;
  }
  ${theme.breakpoints.only('xs')} {
   display: flex;
   position: relative;
   width: 20rem;
   order: 3;
  }
`}
`;
