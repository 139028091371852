import React from 'react';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Hero,
  Introducing,
  EarnIncome,
  TradeTokens,
  StepsOfInvestor,
  VideoSection,
} from './components';
import { OurPartnersSection } from 'views/Company/components';

const VIDEO_LINK =
  'https://www.youtube.com/embed/lkGV0idVDA0?si=aC8b76Q-XaFIWNo7';

const IndexView = (): JSX.Element => {
  return (
    <>
      <Main bgcolor={'background.paper'}>
        <Hero />
        <OurPartnersSection />
        <VideoSection
          title="Digital Transformation of Real Estate Ownership"
          video={VIDEO_LINK}
        />
        <Container>
          <Introducing />
        </Container>
        <Container>
          <EarnIncome />
        </Container>
        <TradeTokens />
        <StepsOfInvestor />
      </Main>
    </>
  );
};

export default IndexView;
