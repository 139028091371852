import React from 'react';
import imageNr1 from 'images/howToSection/1.png';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';
import * as Styles from './styles';
import { GREV55_SITE_LINK } from 'links';

const Benefits = () => (
  <Styles.BenefitsBox>
    {[
      'Free account',
      'Directly from the developer',
      'Private ownership on a public ledger',
    ].map((text, index) => (
      <React.Fragment key={index}>
        <Styles.BenefitsText component="p">
          <Styles.CheckIcon />
          {text}
        </Styles.BenefitsText>
      </React.Fragment>
    ))}
  </Styles.BenefitsBox>
);

const Hero = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  const handleCallToAction = () => {
    window.open(GREV55_SITE_LINK, '_blank');
  };

  return (
    <Styles.ContentBox>
      <Styles.ContentContainer sizeofgrid={sizeOfGrid?.toString()}>
        <Styles.InfoBox>
          <Styles.Title component="h1" variant="h4" color="text.primary">
            Digitized real estate for equal access to everyone.
          </Styles.Title>
          <Styles.InfoText component="p">
            Buy real estate fractions free from old systems, expensive ownership
            transactions and hidden fees. Now, you can purchase physical real
            estate without borders and track your ownership 24/7.
          </Styles.InfoText>

          <Styles.ActionButton
            className="hero-actionButton"
            onClick={handleCallToAction}
            variant="contained"
          >
            View offering
          </Styles.ActionButton>

          <Benefits />
        </Styles.InfoBox>

        <Styles.PictureBox>
          <Styles.Picture
            src={imageNr1}
            component={'img'}
            alt="Illustration of phone that is displaying Coreestate logo and pie chart related to investment offering. "
            height={1}
            width={1}
          />
        </Styles.PictureBox>
      </Styles.ContentContainer>
    </Styles.ContentBox>
  );
};

export default Hero;
