import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import Link from 'layouts/Main/components/Footer/FooterLink';

export const Title = styled(Typography)`
  ${({ theme }) => `
  font-family: Poppins SemiBold;
  text-align: center;

  ${theme.breakpoints.up('md')} {
   font-size: 2.1em;
   line-height: 50px;
   order: initial;
  }
  ${theme.breakpoints.only('sm')} {
   font-size: 1.6em;
   margin: 0 1.0em;
   line-height: 40px;
   order: 2;
  }
  ${theme.breakpoints.only('xs')} {
   font-size: 1.2em;
   margin: 0 0;
   line-height: inherit;
   order: 2;
  }
  `}
` as StyledPropsFix;

export const SubTitle = styled(Typography)`
  ${({ theme }) => `
  font-family: Roboto Regular;

  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;

  ${theme.breakpoints.up('md')} {
   font-size: 1em;
   line-height: 45px;
   text-align: center;
   order: initial;
  }  
  ${theme.breakpoints.only('sm')} {
   font-size: 0.8em;
   line-height: 35px;
   text-align: center;
   order: 3;
  }  
  ${theme.breakpoints.only('xs')} {
   font-size: 0.7em;
   line-height: 35px;
   text-align: center;
   order: 3;
  }
`}
` as StyledPropsFix;

export const ContentBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    background: ${theme.palette.background.level2};
  `}
`;

export const ContentContainer = styled(Container)<{ sizeofgrid?: string }>`
  ${({ theme, sizeofgrid }) => `
  height: 100;
  position: relative;
  padding: 120px 30px;

  ${theme.breakpoints.up('md')} {
    display: initial;
    flex-direction: unset;
    align-items: initial;
    ${sizeofgrid && `max-width: ${sizeofgrid}px;`}
  }
  ${theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }  

`}
`;

export const ProjectLink = styled(Link)`
  ${({ theme }) => `
   font-family: Roboto Regular;
   white-space: nowrap;
   padding-left: 1em;

  ${theme.breakpoints.up('md')} {
   font-size: 1em;
   display: initial;
  }  
  ${theme.breakpoints.only('sm')} {
   font-size: 0.8em;
   display: block;
  }  
  ${theme.breakpoints.only('xs')} {
   font-size: 0.7em;
   display: initial;
  }
`}
`;
